import { FC, useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import IconButton from '@mui/material/IconButton';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';

import { BACKEND_URL } from '../config'
import { fetchWithAuth, fetchWithAuthMethod } from '../utils';


export const EditDevices: FC = () => {
  const [openAddDialog, setOpenAddDialog] = useState(false);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [deviceName, setDeviceName] = useState('');
  const [deviceGroupId, setDeviceGroupId] = useState('');
  const [deviceDescription, setDeviceDescription] = useState('');
  const [deviceModel, setDeviceModel] = useState('');
  const [sigfoxDeviceId, setSigfoxDeviceId] = useState('');
  const [groupOptions, setGroupOptions] = useState<{ label: string; value: string; }[]>([]);
  const [devices, setDevices] = useState<any[]>([]);
  const [selectedDeviceId, setSelectedDeviceId] = useState<string | null>(null); 
  const [isLoading, setIsLoading] = useState(false);
  const [deletingUserId, setDeletingUserId] = useState(null);
  
  const fetchData = async () => {
    try {
      // デバイス、デバイスグループ、およびデバイスグループバインディングの情報を取得
      const [devicesResponse, groupsResponse, bindingsResponse] = await Promise.all([
        fetchWithAuth(`${BACKEND_URL}/devices`).then(x => x.json()),
        fetchWithAuth(`${BACKEND_URL}/device_groups`).then(x => x.json()),
        fetchWithAuth(`${BACKEND_URL}/device_group_bindings`).then(x => x.json())
      ]);

      // デバイスのグループ情報をデバイスデータに結合してセット
      const devicesWithGroups = devicesResponse.map((device: any) => {
        // device_group_bindingsから該当するdevice_idとgroup_idを見つける
        const binding = bindingsResponse.find((binding: any) => binding.device_id === device.id);
        if (binding) {
          // 該当するグループの情報を取得
          const group = groupsResponse.find((group: any) => group.id === binding.group_id);
          if (group) {
            // デバイスのグループ情報を新しいオブジェクトとしてセット
            device.groupName = {
              label: group.name,
              value: group.id
            };
          }
        } else {
          // バインディングが見つからない場合は空文字列をセット
          device.groupName = {
            label: '',
            value: ''
          };
        }
        // sigfox_device_idをデバイスデータに追加
        device.sigfoxDeviceId = device.config?.sigfox_device_id;
        return device;
      });

      // デバイスデータをセット
      setDevices(devicesWithGroups);

      const groupOptionsData = Array.isArray(groupsResponse) ? groupsResponse : [groupsResponse];
      setGroupOptions(groupOptionsData.map((group: any) => ({
        label: group.name,
        value: group.id,
      })));
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  
  useEffect(() => {
    fetchData();
  }, []);

  const handleEditClick = async (row: any) => {
    setSelectedDeviceId(row.id); // デバイスのIDを状態に保存
    setDeviceName(row.name); // デバイスの名前をフォームにセット
    // デバイスのグループをフォームにセット
    const groupId = row.groupName ? row.groupName.value : ''; // row.groupName が存在する場合はその value を使用し、存在しない場合は空文字列をセットする
    setDeviceGroupId(groupId);
    setDeviceDescription(row.description); // 説明をフォームにセット
    setDeviceModel(row.model); // モデルをフォームにセット
    setSigfoxDeviceId(row.sigfoxDeviceId); // sigfox_device_idをフォームにセット
    setOpenEditDialog(true); // 編集ダイアログを開く

    setTimeout(() => {
      document.getElementById('device-name-input')?.focus(); // IDを使ってフォーカス
    }, 0);
  };
  
  const handleEditDevice = async () => {
    if (!selectedDeviceId) return; // デバイスIDがない場合は処理しない

    // 確認メッセージを表示
    const confirmed = window.confirm('更新してもよろしいですか？');
    if (!confirmed) return; // ユーザーがキャンセルした場合は処理しない

    setIsLoading(true); // ローディング開始

    // device_group_bindingを同時に更新する
    const response = await fetchWithAuthMethod(`${BACKEND_URL}/devices/update_with_group_binding/${selectedDeviceId}?group_id=${deviceGroupId}`, `PUT`, {
      name: deviceName,
      description: deviceDescription,
      model: deviceModel,
      config: {
        sigfox_device_id: sigfoxDeviceId
      }
    });

    if (response.ok) {
      // デバイス更新が成功した場合はデバイスリストを更新
      const updatedDevice = await response.json();
      // デバイスリスト更新
      setDevices(prevDevices => {
        const newDevices = [...prevDevices];
        const index = newDevices.findIndex(device => device.id === selectedDeviceId);    
        if (0 <= index) {
          // 指定デバイスのデータのみ更新
          newDevices[index] = {
            ...updatedDevice,
            sigfoxDeviceId: updatedDevice.config.sigfox_device_id
          };
          const group = groupOptions.find((group: any) => group.value === deviceGroupId);
          if (group) {
            newDevices[index].groupName = {
              label: group.label,
              value: group.value
            };
          }
        }
        return newDevices;
      });

      handleCloseDialog('edit'); // 編集ダイアログを閉じる
    } else {
      console.error('Failed to update device');
    }

    setIsLoading(false); // ローディング終了
  };

  const handleCloseDialog = (dialogType: 'add' | 'edit', event?: React.SyntheticEvent<Element, Event> | {}, reason?: string) => {
    // 背景をクリックされるとダイアログの各値がクリアされてしまう対策
    if (reason && reason === 'backdropClick') {
      return;
    }
    
    setSelectedDeviceId(null); // 編集が終了したので選択したデバイスIDをクリア
    setDeviceName(''); // フォームの値をクリア
    setDeviceGroupId('');
    setDeviceModel('');
    setSigfoxDeviceId('');
    setDeviceDescription('');
	
    if (dialogType === 'add') {
      setOpenAddDialog(false); // 追加ダイアログを閉じる
    } else if (dialogType === 'edit') {
      setOpenEditDialog(false); // 編集ダイアログを閉じる
    }
  };

  const handleDeleteClick = async (row: any) => {
    // 確認メッセージを表示
    const confirmed = window.confirm('削除してもよろしいですか？\nデバイスに登録されているチャネル情報も削除されます。');
    if (!confirmed) return; // ユーザーがキャンセルした場合は処理しない

    setDeletingUserId(row.id); // ローディング開始
     
    // デバイス削除
    const response = await fetchWithAuthMethod(`${BACKEND_URL}/devices/${row.id}`, `DELETE`);
    if (response.ok) {
      // デバイスリスト更新
      setDevices(devices.filter((device: any) => device.id !== row.id));
    } else {
      console.error('Failed to delete device');
    }

    setDeletingUserId(null); // ローディング終了
  };
  
  const handleAddClick = () => {
    const defaultGroupId = groupOptions.length > 0 ? groupOptions[0].value : '';
    setDeviceGroupId(defaultGroupId);  // setDeviceGroupId を使用して deviceGroupId の値を更新
    setOpenAddDialog(true);

    setTimeout(() => {
      document.getElementById('device-name-input')?.focus(); // IDを使ってフォーカス
    }, 0);
  };

  const handleAddDevice = async () => {
    // 確認メッセージを表示
    const confirmed = window.confirm('追加してもよろしいですか？');
    if (!confirmed) return; // ユーザーがキャンセルした場合は処理しない

    setIsLoading(true); // ローディング開始

    // デバイスの追加
    // device_group_bindingを同時に追加する
    const response = await fetchWithAuthMethod(`${BACKEND_URL}/devices/create_with_group_binding?group_id=${deviceGroupId}`, `POST`, {
      name: deviceName,
      description: deviceDescription,
      model: deviceModel,
      config: {
        sigfox_device_id: sigfoxDeviceId
      }
    });

    if (response.ok) {
      // 新しく作成されたデバイスを取得
      const newDevice = await response.json();
      // デバイスリスト更新
      setDevices(prevDevices => {
        // 新しいデバイスの情報を準備
        const addDevice = {
          ...newDevice,
          sigfoxDeviceId: newDevice.config.sigfox_device_id
        };
      
        // デバイスグループの情報を検索して追加
        const group = groupOptions.find((group: any) => group.value === deviceGroupId);
        if (group) {
          addDevice.groupName = {
            label: group.label,
            value: group.value
          };
        }
      
        // 既存のデバイスリストに新しいデバイスを追加
        return [...prevDevices, addDevice];
      });
	
      handleCloseDialog('add'); // 追加ダイアログを閉じる
    } else {
      console.error('Failed to add device');
    }

    setIsLoading(false); // ローディング終了
  };

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: '端末名称',
      editable: false,
      width: 160,
    },
    {
      field: 'groupName',
      headerName: '端末グループ',
      editable: false,
      width: 160,
      valueGetter: (params) => params.row.groupName ? params.row.groupName.label : ''
    },
    {
      field: 'description',
      headerName: '説明',
      editable: false,
      width: 160,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: 'model',
      headerName: 'モデル',
      editable: false,
      width: 160,
    },
    {
      field: 'sigfoxDeviceId',
      headerName: 'SigfoxId',
      editable: false,
      width: 160,
    },
    {
      field: 'edit',
      headerName: '編集',
      width: 60,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => (
        <IconButton aria-label="edit" onClick={() => handleEditClick(params.row)}>
          <EditIcon />
        </IconButton>
      ),
    },
    {
      field: 'delete',
      headerName: '削除',
      width: 60,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => (
        <IconButton aria-label="delete" onClick={() => handleDeleteClick(params.row)}>
            {deletingUserId === params.row.id ? (
              <CircularProgress size={24} />
            ) : (
              <DeleteIcon />
            )}
        </IconButton>
      ),
    }
  ];

  return (
    <Box sx={{ height: '80vh', width: '100%' }}>
      <Box sx={{ display: 'flex', flexDirection: 'column', height: 'calc(100vh - 250px)' }} id="datalist-grid">
        <DataGrid
          rows={devices}
          columns={columns}
          disableSelectionOnClick
          experimentalFeatures={{ newEditingApi: true }}
          density="compact"
          hideFooter
          sx={{ flexGrow: 1, overflowY: 'auto',
            "& .MuiDataGrid-cell:focus": {
              outline: "none",
            },
            "& .MuiDataGrid-cell:focus-within": {
              outline: "none",
            },
            "& .Mui-selected": {
              outline: "none",
            },
          }}
        />
      </Box>
      <IconButton aria-label="add" onClick={handleAddClick}>
        <AddIcon />
		<Typography variant="body1">追加</Typography>
      </IconButton>
      <Dialog open={openAddDialog} onClose={(event, reason) => handleCloseDialog('add', event || {}, reason)}>
        <DialogTitle>端末の追加</DialogTitle>
        <DialogContent>
          <TextField
            id="device-name-input"
            autoFocus
            margin="dense"
            label="端末名称"
            fullWidth
            value={deviceName}
            onChange={(e) => setDeviceName(e.target.value)}
          />
          <TextField
            select
            margin="dense"
            label="端末グループ"
            fullWidth
            value={deviceGroupId}
            onChange={(e) => setDeviceGroupId(e.target.value)}
          >
		    {groupOptions.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            margin="dense"
            label="説明"
            fullWidth
            value={deviceDescription}
            onChange={(e) => setDeviceDescription(e.target.value)}
          />
          <TextField
            margin="dense"
            label="モデル"
            fullWidth
            value={deviceModel}
            onChange={(e) => setDeviceModel(e.target.value)}
          />
          <TextField
            margin="dense"
            label="sigfoxId"
            fullWidth
            value={sigfoxDeviceId}
            onChange={(e) => setSigfoxDeviceId(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleCloseDialog('add')}>キャンセル</Button>
          {isLoading ? (
            <CircularProgress /> // MUIのスピナーを表示
          ) : (
            <Button 
              onClick={handleAddDevice}
              disabled={(!deviceName || 0 >= deviceName.length)}
            >
              追加
            </Button>
          )}        
        </DialogActions>
      </Dialog>
      <Dialog open={openEditDialog} onClose={(event, reason) => handleCloseDialog('edit', event || {}, reason)}>
        <DialogTitle>端末の編集</DialogTitle>
        <DialogContent>
          <TextField
            id="device-name-input" 
            autoFocus
            margin="dense"
            label="端末名称"
            fullWidth
            value={deviceName}
            onChange={(e) => setDeviceName(e.target.value)}
          />
          <TextField
            select
            margin="dense"
            label="端末グループ"
            fullWidth
            value={deviceGroupId}
            onChange={(e) => setDeviceGroupId(e.target.value)}
          >
            {groupOptions.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            margin="dense"
            label="説明"
            fullWidth
            value={deviceDescription}
            onChange={(e) => setDeviceDescription(e.target.value)}
          />
          <TextField
            margin="dense"
            label="モデル"
            fullWidth
            value={deviceModel}
            onChange={(e) => setDeviceModel(e.target.value)}
          />
          <TextField
            margin="dense"
            label="sigfoxId"
            fullWidth
            value={sigfoxDeviceId}
            onChange={(e) => setSigfoxDeviceId(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleCloseDialog('edit')}>キャンセル</Button>
          {isLoading ? (
            <CircularProgress /> // MUIのスピナーを表示
          ) : (
            <Button 
              onClick={handleEditDevice}
              disabled={(!deviceName || 0 >= deviceName.length)}
            >
              更新
            </Button>            
          )} 
        </DialogActions>
      </Dialog>
    </Box>
  );
}