import { FC, useState, useEffect } from 'react';
import { useSearchParams } from "react-router-dom";
import Box from '@mui/material/Box';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import 'chartjs-adapter-date-fns';
import { enUS } from 'date-fns/locale';
import 'chart.js/auto'
import { Line } from 'react-chartjs-2'

import { BACKEND_URL } from '../config'
import { fetchWithAuth, groupBy } from '../utils'
import { toGlobal, toLocal, todayString } from '../utils/dateUtils'

// グラフ用カラー定義
const colors = [
  '237,  28,  36',
  '  0, 128, 255',
  '255, 201,  14',
  ' 34, 177,  76',
  '181, 119, 255',
  '255, 127,  39',
  ' 68, 208, 255',
  '255, 140, 198',
];

const addDays = (date: Date, days: number) => {
  let copy = new Date(date.valueOf());
  copy.setDate(copy.getDate() + days);
  return copy;
};

const addYears = (date: Date, years: number) => {
  let copy = new Date(date.valueOf());
  copy.setFullYear(copy.getFullYear() + years);
  return copy;
};

const getDateFrom = (searchParams: URLSearchParams) => {
  return toGlobal(new Date(searchParams.get('from') ?? todayString));
};

const getDateTo = (searchParams: URLSearchParams) => {
  return toGlobal(new Date(searchParams.get('to') ?? todayString));
};

export const Charts: FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [plotData, setPlotData] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    let buffer: any[] = [];

    const fetchData = async () => {
      try{
        setLoading(true);
        const dateFrom = getDateFrom(searchParams);
        console.log(`dateFrom=${dateFrom}`);
        const dateTo = getDateTo(searchParams);
        console.log(`dateTo=${dateTo}`);
        console.log(`dateTo-dateFrom=${dateTo.getTime()-dateFrom.getTime()}`);
        const isPreviousYear = searchParams.get('isPreviousYear') === 'true';
        const channels: any[] = await fetchWithAuth(
          `${BACKEND_URL}/channels`
          ).then(x => x.json());
        const channel_groups: any[] = await fetchWithAuth(
          `${BACKEND_URL}/channel_groups`
          ).then(x => x.json());
          
        for (let i = 0; i < channel_groups.length; i++) {
          while(true){
            let url = `${BACKEND_URL}/records?channel_group_id=${channel_groups[i].id}`;
            url = `${url}&timestamp_from=${addDays(dateFrom, -1).toISOString().slice(0,19)}`;
            url = `${url}&timestamp_to=${addDays(dateTo, +1).toISOString().slice(0,19)}`;
            url = `${url}&skip=${buffer.length}`;
            const records = await fetchWithAuth(url).then(x => x.json());
            if (records.length === 0){
              break;
            }
            buffer.push(...records);
          }
        }

        const groups = groupBy(buffer, (x: any) => x.channel_id);
        let plotData = Object.keys(groups).map((channel_id: string, i: number) => ({
          label: channels.find((x: any) => x.id == parseInt(channel_id))?.name ?? channel_id,
          data: groups[channel_id].map((e: any) => ({
            x: toLocal(new Date(e.timestamp)),
            y: e.phy_value,
          })),
          backgroundColor: [`rgba(${colors[i % colors.length]}, 1)`],
          borderColor: [`rgba(${colors[i % colors.length]}, 1)`],
          borderWidth: 1,
          lineTension: 0,
          fill: false,
        })).sort((a, b) => `${a.label}`.localeCompare(`${b.label}`));

        // 前年データの取得
        if (isPreviousYear) {
          let previousYearBuffer: any[] = [];
          for (let i = 0; i < channel_groups.length; i++) {
            while(true){
              let url = `${BACKEND_URL}/records?channel_group_id=${channel_groups[i].id}`;
              url = `${url}&timestamp_from=${addYears(dateFrom, -1).toISOString().slice(0,19)}`;
              url = `${url}&timestamp_to=${addYears(dateTo, -1).toISOString().slice(0,19)}`;
              url = `${url}&skip=${previousYearBuffer.length}`;
              const records = await fetchWithAuth(url).then(x => x.json());
              if (records.length === 0){
                break;
              }
              previousYearBuffer.push(...records);
            }
          }

          const previousYearGroups = groupBy(previousYearBuffer, (x: any) => x.channel_id);
          const previousYearPlotData = Object.keys(previousYearGroups).map((channel_id: string, i: number) => ({
            label: `${channels.find((x: any) => x.id == parseInt(channel_id))?.name ?? channel_id} (前年)`,
            data: previousYearGroups[channel_id].map((e: any) => ({
              x: addYears(toLocal(new Date(e.timestamp)), 1), // 現在の年に変換
              y: e.phy_value,
            })),
            backgroundColor: [`rgba(${colors[i % colors.length]}, 0.5)`],
            borderColor: [`rgba(${colors[i % colors.length]}, 0.5)`],
            borderWidth: 1,
            lineTension: 0,
            fill: false,
            borderDash: [5, 5], // 点線で区別
          })).sort((a, b) => `${a.label}`.localeCompare(`${b.label}`));

          plotData = [...plotData, ...previousYearPlotData];
        }

        setPlotData(plotData);
      }finally{
        setLoading(false);
      }
    };

    fetchData()
    .catch(console.error);
  }, [searchParams]);

  return (
    <Box sx={{ height: '80vh', width: '100%' }}>
      <Backdrop
        sx={{ color: '#ccc', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Line
        data={{datasets: plotData}}
        options={{
          plugins:{
            legend: {
              display: true,
              position: 'bottom',
            },
          },
          animation: false,
          maintainAspectRatio: false,
          scales: {
            x: {
              min: getDateFrom(searchParams).getTime(),
              max: addDays(getDateTo(searchParams), +1).getTime(),
              type: 'time',
              time: {
                unit: (addDays(getDateTo(searchParams), +1).getTime()-getDateFrom(searchParams).getTime() > 24*60*60*1000) ? 'day':'hour',
                displayFormats: {
                  'hour': 'M月d日 HH:mm',
                  'day': 'M月d日'
                },
              },
              adapters: { 
                date: {
                  locale: enUS, 
                },
              }, 
            },
            y: {
              beginAtZero: true,
            },
          },
          elements: {
            point: {radius: 0, hitRadius: 5},
          }
          
        }}
      />
    </Box>
  );
};
