import { format } from 'date-fns';

export const formatUnixTime = (x: Date) => {  
    if (!x || isNaN(x.getTime())) {
        return '';  // xがnullまたはundefinedの場合、空文字を返す
    }

    const now = new Date();
    const f = (x.getFullYear() === now.getFullYear()) ? 'MM/dd\u00A0\u00A0\u00A0\u00A0HH:mm' : 'yyyy/MM/dd\u00A0\u00A0\u00A0\u00A0HH:mm';
    return format(x, f);
};

export const toGlobal = (date: Date) => {
    var offset = new Date().getTimezoneOffset();
    let copy = new Date(date.valueOf());
    copy.setMinutes(copy.getMinutes() + offset);
    return copy;
  };
  
export const toLocal = (date: Date) => {
    var offset = new Date().getTimezoneOffset();
    let copy = new Date(date.valueOf());
    copy.setMinutes(copy.getMinutes() - offset);
    return copy;
  };

export const todayString = toLocal(new Date()).toJSON().slice(0, 10);