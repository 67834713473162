import React, { FC, useState, useEffect, ChangeEvent } from 'react';
import Box from '@mui/material/Box';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import IconButton from '@mui/material/IconButton';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';

import { BACKEND_URL } from '../config'
import { fetchWithAuth, fetchWithAuthMethod } from '../utils';

const BACKEND_URLS = {
  '端末グループ': 'device_groups',
  '地点グループ': 'channel_groups',
  'ユーザーグループ': 'user_groups',
};

const RESOURCE_TYPES = {
  '端末グループ': 'device_group',
  '地点グループ': 'channel_group',
  'ユーザーグループ': 'user_group',
};


export const EditGroups: FC = () => {
  const [openAddDialog, setOpenAddDialog] = useState(false);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [groupName, setGroupName] = useState('');
  const [groupDescription, setGroupDescription] = useState('');
  const [groupType, setGroupType] = useState<'端末グループ' | '地点グループ' | 'ユーザーグループ'>('端末グループ');
  const [groups, setGroups] = useState<any[]>([]);
  const [selectedGroupId, setSelectedGroupId] = useState<string | null>(null); 
  const [userGroups, setUserGroups] = useState<any[]>([]); // ユーザーグループの状態追加
  const [selectedUserGroupId, setSelectedUserGroupId] = useState<string | null>(null); // 選択されたユーザーグループID
  const [isLoading, setIsLoading] = useState(false);
  const [deletingUserId, setDeletingUserId] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
	    const url = `${BACKEND_URL}/${BACKEND_URLS[groupType]}`;
      const groupsData = await fetchWithAuth(url).then(x => x.json());
      setGroups(groupsData.map((x: any) => ({
        id: x.id,
        name: x.name,
        description: x.description,
        userGroupId: x.userGroupId || null,
      })));
    };

    const fetchUserGroups = async () => {
      const userGroupsData = await fetchWithAuth(`${BACKEND_URL}/user_groups`).then(x => x.json());
      setUserGroups(userGroupsData);
    };
    
    fetchData().catch(console.error);
    fetchUserGroups().catch(console.error);
  }, [groupType]);

  const fetchUserGroupIdForPermission = async (resourceType: string, groupId: string) => {
    try {
      const permissionsUrl = `${BACKEND_URL}/permission?resource_type=${resourceType}&resource_id=${groupId}`;
      const response = await fetchWithAuth(permissionsUrl);
      const permissionsData = await response.json();
      if (Array.isArray(permissionsData)) {
        const userGroupId = permissionsData[0].user_group_id;
        return userGroupId;
      } else {
        console.error('Expected permissionsData to be an array, but got:', permissionsData);
        return null;
      }
    } catch (error) {
      console.error('Failed to fetch permissions:', error);
      return null;
    }
  };
  
  const handleTypeChange = (e: ChangeEvent<{ value: unknown }>) => {
    setGroupType(e.target.value as "端末グループ" | "地点グループ" | "ユーザーグループ");
  };

  const handleAddClick = () => {
    setOpenAddDialog(true);
 
    setTimeout(() => {
      document.getElementById('group-name-input')?.focus(); // IDを使ってフォーカス
    }, 0);
  };

  const handleAddGroup = async () => {
    // 確認メッセージを表示
    const confirmed = window.confirm('追加してもよろしいですか？');
    if (!confirmed) return; // ユーザーがキャンセルした場合は処理しない

    // ユーザーグループが選択されているかチェック
    if (groupType !== 'ユーザーグループ' && !selectedUserGroupId) {
      alert('ユーザーグループを選択してください。');
      return;
    }

    setIsLoading(true); // ローディング開始

    let url = `${BACKEND_URL}/${BACKEND_URLS[groupType]}`;
    if (groupType !== 'ユーザーグループ') {
      // デバイスグループ、チャネルグループ追加時はpermissionテーブルも追加する
      url = url + `/create_with_permission?user_group_id=${selectedUserGroupId}`;
    }
    const response = await fetchWithAuthMethod(url, `POST`, {
      name: groupName,
      description: groupDescription
    });

    if (response.ok) {
      const newGroup = await response.json();
      setGroups([...groups, newGroup]); // グループリストに新しいグループを追加する

      handleCloseDialog('add');      
    } else {
      console.error('Failed to add group');
    }

    setIsLoading(false); // ローディング終了
  };

  const handleEditClick = async (row: any) => {
    setSelectedGroupId(row.id); // グループのIDを状態に保存
    setGroupName(row.name); // グループの名前をフォームにセット
    setGroupDescription(row.description); // 説明をフォームにセット

    // デバイスグループ、チャネルグループ更新時はpermission情報からuserGroupId取得
    if (groupType !== 'ユーザーグループ') {
      const userGroupId = await fetchUserGroupIdForPermission(RESOURCE_TYPES[groupType], row.id);
      setSelectedUserGroupId(userGroupId);
    }

    setOpenEditDialog(true); // 編集ダイアログを開く

    setTimeout(() => {
      document.getElementById('group-name-input')?.focus(); // IDを使ってフォーカス
    }, 0);
  };
   
  const handleEditGroup = async () => {
    if (!selectedGroupId) return; // GroupIDがない場合は処理しない

    // 確認メッセージを表示
    const confirmed = window.confirm('更新してもよろしいですか？');
    if (!confirmed) return; // ユーザーがキャンセルした場合は処理しない

    // ユーザーグループが選択されているかチェック
    if (groupType !== 'ユーザーグループ' && !selectedUserGroupId) {
      alert('ユーザーグループを選択してください。');
      return;
    }

    setIsLoading(true); // ローディング開始
    
	  let url = `${BACKEND_URL}/${BACKEND_URLS[groupType]}`;
    if (groupType !== 'ユーザーグループ') {
      // デバイスグループ、チャネルグループ更新時はpermissionテーブルも更新する
      url = url + `/update_with_permission/${selectedGroupId}?user_group_id=${selectedUserGroupId}`;
    } else {
      url = url + `/${selectedGroupId}`;
    }
    const response = await fetchWithAuthMethod(url, `PUT`, {
      name: groupName,
      description: groupDescription
    });

    if (response.ok) {
      // 更新が成功した場合はグループリストを更新
      const updatedGroup = await response.json();
      setGroups(groups.map(group => (group.id === selectedGroupId ? updatedGroup : group)));

      handleCloseDialog('edit'); // 編集ダイアログを閉じる
    } else {
      console.error('Failed to update group');
    }

    setIsLoading(false); // ローディング終了
  };

  const handleDeleteClick = async (row: any) => {
    // 確認メッセージを表示
    const confirmed = window.confirm('削除してもよろしいですか？');
    if (!confirmed) return; // ユーザーがキャンセルした場合は処理しない

    setDeletingUserId(row.id); // ローディング開始

    // 削除処理
    const url = `${BACKEND_URL}/${BACKEND_URLS[groupType]}/${row.id}`;
    const response = await fetchWithAuthMethod(url, `DELETE`);
    if (response.ok) {
      // グループリスト更新
      setGroups(groups.filter((group: any) => group.id !== row.id));
    } else {
      console.error('Failed to delete group');
    }

    setDeletingUserId(null); // ローディング終了
  };

  const handleCloseDialog = (dialogType: 'add' | 'edit', event?: React.SyntheticEvent<Element, Event> | {}, reason?: string) => {
    // 背景をクリックされるとダイアログの各値がクリアされてしまう対策
    if (reason && reason === 'backdropClick') {
      return;
    }

    // フォームの値をクリア
    setSelectedGroupId(null);
    setGroupName(''); 
    setGroupDescription('');
    setSelectedUserGroupId(null);

    if (dialogType === 'add') {
      setOpenAddDialog(false);
    } else if (dialogType === 'edit') {
      setOpenEditDialog(false);
    }
  };
  

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: 'グループ名称',
      editable: true,
      width: 160,
    },
    {
      field: 'description',
      headerName: '説明',
      editable: false,
      width: 160,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: 'edit',
      headerName: '編集',
      width: 60,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => (
        <IconButton aria-label="edit" onClick={() => handleEditClick(params.row)}>
          <EditIcon />
        </IconButton>
      ),
    },
    {
      field: 'delete',
      headerName: '削除',
      width: 60,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => (
        <IconButton aria-label="delete" onClick={() => handleDeleteClick(params.row)}>
          {deletingUserId === params.row.id ? (
            <CircularProgress size={24} />
          ) : (
            <DeleteIcon />
          )}
      </IconButton>
      ),
    }
  ];

  return (
    <Box sx={{ height: '70vh', width: '100%', marginTop: 2 }}>
      <Box sx={{ display: 'flex', flexDirection: 'column', height: 'calc(100vh - 250px)', marginBottom: 2 }} id="datalist-grid">
        {/* グループ種類のコンボボックス */}
        <TextField
          select
          margin="dense"
          label="グループ種類"
          fullWidth
          value={groupType}
          onChange={(e: React.ChangeEvent<{ value: unknown }>) => handleTypeChange(e)}
          SelectProps={{
            MenuProps: {
              PaperProps: {
                style: {
                  width: 'auto',  // ドロップダウンメニューの幅を自動調整
                  minWidth: '95%', // コンボボックスの幅に合わせる
                },
              },
            },
          }}
        >
          {Object.keys(BACKEND_URLS).map((type) => (
            <MenuItem key={type} value={type}>
              {type}
            </MenuItem>
          ))}
        </TextField>
      
        <Box sx={{ marginBottom: 2 }} />

        {/* データグリッド */}
        <DataGrid
          rows={groups}
          columns={columns}
          disableSelectionOnClick
          density="compact"
          hideFooter
          sx={{ flexGrow: 1, overflowY: 'auto',
            "& .MuiDataGrid-cell:focus": {
              outline: "none",
            },
            "& .MuiDataGrid-cell:focus-within": {
              outline: "none",
            },
            "& .Mui-selected": {
              outline: "none",
            },
          }}
        />
      </Box>

      {/* 追加ボタン */}
      <IconButton aria-label="add" onClick={handleAddClick}>
        <AddIcon />
		    <Typography variant="body1">追加</Typography>
      </IconButton>

      {/* グループ追加ダイアログ */}
      <Dialog open={openAddDialog} onClose={(event, reason) => handleCloseDialog('add', event || {}, reason)}>
        <DialogTitle>グループの追加</DialogTitle>
        <DialogContent>
          <TextField
            id="group-name-input"
            autoFocus
            margin="dense"
            label="グループ名称"
            fullWidth
            value={groupName}
            onChange={(e) => setGroupName(e.target.value)}
          />
          <TextField
            margin="dense"
            label="説明"
            fullWidth
            value={groupDescription}
            onChange={(e) => setGroupDescription(e.target.value)}
          />
          {groupType !== 'ユーザーグループ' && (
            <TextField
              select
              margin="dense"
              label="ユーザーグループ"
              fullWidth
              value={selectedUserGroupId || ''}
              onChange={(e) => setSelectedUserGroupId(e.target.value)}
            >
              {userGroups.map((userGroup) => (
                <MenuItem key={userGroup.id} value={userGroup.id}>
                  {userGroup.name}
                </MenuItem>
              ))}
            </TextField>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleCloseDialog('add')}>キャンセル</Button>
          {isLoading ? (
              <CircularProgress /> // MUIのスピナーを表示
            ) : (
              <Button 
                onClick={handleAddGroup}
                disabled={(groupType !== 'ユーザーグループ' && !selectedUserGroupId) || (!groupName || 0 >= groupName.length)}
              >
                追加
              </Button>
            )}
        </DialogActions>
      </Dialog>
      <Dialog open={openEditDialog} onClose={(event, reason) => handleCloseDialog('edit', event || {}, reason)}>
        <DialogTitle>グループの編集</DialogTitle>
        <DialogContent>
          <TextField
            id="group-name-input" 
            autoFocus
            margin="dense"
            label="グループ名称"
            fullWidth
            value={groupName}
            onChange={(e) => setGroupName(e.target.value)}
          />
          <TextField
            margin="dense"
            label="説明"
            fullWidth
            value={groupDescription}
            onChange={(e) => setGroupDescription(e.target.value)}
          />
          {groupType !== 'ユーザーグループ' && (
            <TextField
              select
              margin="dense"
              label="ユーザーグループ"
              fullWidth
              value={selectedUserGroupId || ''}
              onChange={(e) => setSelectedUserGroupId(e.target.value)}
            >
              {userGroups.map((userGroup) => (
                <MenuItem key={userGroup.id} value={userGroup.id}>
                  {userGroup.name}
                </MenuItem>
              ))}
            </TextField>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleCloseDialog('edit')}>キャンセル</Button>
          {isLoading ? (
            <CircularProgress /> // MUIのスピナーを表示
          ) : (
            <Button 
              onClick={handleEditGroup}
              disabled={(groupType !== 'ユーザーグループ' && !selectedUserGroupId) || (!groupName || 0 >= groupName.length)}
            >
              更新
            </Button>
          )}
        </DialogActions>
      </Dialog>
	</Box>
  );
}